<template>
  <div :id="elemId" class="number-chooser input-group" style="height:100%">
    <template v-if="isLoadingDuplicates">
      <div class="text-center w-100 text-warning number-chooser-spinner">
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      </div>
    </template>
    <template v-else>
      <input
        :maxlength="maxlength"
        :id="elemId + '_input'"
        type="text"
        class="form-control"
        :class="{ error: isError }"
        style="height:100%"
        v-model="currentNumber"
        :disabled="isSystemNumber || isDisabled"
        @input="handleInput"/>
    </template>
    <span
      v-if="isAllowUserNumbers && !isDisabled"
      @click="switchNumberingSource();"
      class="input-group-addon classic ml-1"
      style="cursor:pointer">
        <i
          class="fa fa-pencil"
          :title="sourceText"></i>
    </span>
    <span
      v-if="duplicates.length > 0 && isUserNumber"
      class="input-group-addon ml-1"
      style="height:100%"
      >
      <button
        v-if="isLoadingDuplicates"
        disabled
        class="btn btn-yellow"
        type="button"
        @click="showDuplicates();"
        :title="'Show Duplicates'">...
      </button>
      <button
        v-else
        class="btn btn-warning"
        type="button"
        @click="showDuplicates();"
        :title="'Show Duplicates'">!
      </button>
    </span>
    <ConfirmDialog
      ref="confirmDuplicates"
      class="tw-shipping-modal"
      :maxWidth="'500px'"
      title="Duplicate Number"
      okBtnText="OK"
      :isOverlapping="isOverlapping"
      :showCancel="false">
      <template v-slot:body>
        <div>
          {{$t('message.numberDuplication', { number: currentNumber })}}
        </div>
        <div class="ml-5">
          <ul>
            <li v-for="(duplicate, index) in duplicates" :key="duplicate.Description + index">{{duplicate.Description}}</li>
          </ul>
        </div>
      </template>
    </ConfirmDialog>
  </div>
</template>

<script>
import { errorHandler, generateUniqueId } from '../../helpers/utils.js'
import axios from 'axios'
import $ from 'jquery'
import ConfirmDialog from './ConfirmDialog.vue'
export default {
  name: 'NumberChooser',
  components: {
    ConfirmDialog
  },
  props: {
    numberType: {
      type: Number,
      required: true
    },
    isCatalogAddItem: {
      type: Boolean,
      default: false,
      required: false
    },
    number: {
      type: String,
      default: ''
    },
    isRequired: {
      type: Boolean,
      default: true
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    isError: {
      type: Boolean,
      default: false
    },
    isOverlapping: {
      type: Boolean,
      required: false,
      default: true
    },
    isTransferPage: {
      type: Boolean,
      required: false,
      default: false
    },
    isPOPage: {
      type: Boolean,
      required: false,
      default: false
    },
    isPurchaseOrderEditing: {
      type: Boolean,
      required: false,
      default: false
    },
    isTransferEditing: {
      type: Boolean,
      required: false,
      default: false
    },
    maxlength: {
      type: String,
      default: null
    },
    isBarcodeBlank: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function () {
    return {
      elemId: 'number_chooser_' + generateUniqueId(),
      duplicates: [],
      startingSource: 1,
      currentSource: 1,
      startingNumber: '',
      currentNumber: this.number,
      fetchedNumbersBySource: new Map(),
      isInitialized: false,
      isLoadingDuplicates: false,
      transferNumber: this.number,
      poNumber: this.number,
      isAllowBarcodeBlank: false,
      duplicateTimeout: null,
      isDuplicate: false
    }
  },
  mounted: async function () {
    const number = this.poNumber
    if (!this.isInitialized) {
      const transferDataWrapper = JSON.parse(localStorage.getItem('transferDataWrapper'))

      if (this.isTransferPage && !this.isTransferEditing &&
      transferDataWrapper !== null && transferDataWrapper.Number !== '') {
        this.transferNumber = JSON.parse(localStorage.getItem('transferDataWrapper')).Number
        this.setTransferNumber()
      } else if (this.isPOPage && !this.isPurchaseOrderEditing && JSON.parse(localStorage.getItem('poDataWrapper')) != null && JSON.parse(localStorage.getItem('poDataWrapper'))?.Number != '') {
        this.poNumber = JSON.parse(localStorage.getItem('poDataWrapper')).Number
        this.setPONumber()
      } else {
        this.currentNumber = this.number
        await this.loadNextNumberMetadata()
        if (!this.isBarcodeBlank) {
          await this.loadCurrentNumber()
        }
      }
      this.$emit('saveUsedNumber', this.saveUsedNumber)
      this.$emit('getNewNumber', this.getNewNumber)
    }
  },
  methods: {
    async loadCurrentNumber () {
      // If we've fetched a number or one was provided, use that number
      const existingNumber = this.fetchedNumbersBySource.get(this.currentSource)
      if (existingNumber) {
        this.setCurrentNumber(existingNumber, true)
        this.isInitialized && this.checkForDuplicates()
        return
      }
      await this.getNewNumber()
    },
    setTransferNumber () {
      this.fetchedNumbersBySource.set(this.currentSource, this.transferNumber)
      this.isLoadingDuplicates = false
      this.isInitialized = true
      this.setCurrentNumber(this.transferNumber, true)
    },
    setPONumber () {
      this.fetchedNumbersBySource.set(this.currentSource, this.poNumber)
      this.isLoadingDuplicates = false
      this.isInitialized = true
      this.setCurrentNumber(this.poNumber, true)
    },
    async getNewNumber () {
      this.isLoadingDuplicates = true
      // Otherwise, generate a new number for the given number type and source
      // and save it to the fetched numbers
      const params = JSON.stringify({
        numberType: '\'' + this.numberType + '\'',
        requestedNumberSource: '\'' + this.currentSource + '\''
      })
      try {
        const { data } = await axios.post('/api/core/NextNumberService/GetNextNumber', params)
        const { Number, KnownDuplicates } = data.Body
        if (this.numberType === 1) {
          this.isAllowBarcodeBlank = data?.Body?.AllowBlank
        }
        this.$emit('isAllowBarcodeBlank', this.isAllowBarcodeBlank ? this.isAllowBarcodeBlank : false)
        const newNumber = Number
        this.duplicates = KnownDuplicates
        this.fetchedNumbersBySource.set(this.currentSource, newNumber)
        this.isLoadingDuplicates = false
        this.isInitialized = true
        this.setCurrentNumber(newNumber, true)
      } catch (error) {
        if (error.response) {
          errorHandler(this.$toast, error.response.statusText, error)
        }
      }
    },
    async loadNextNumberMetadata () {
      this.startingNumber = this.number

      // If no number provided, don't try to look it up
      if (!this.number || this.number.length === 0) {
        this.setCurrentNumber(this.startingNumber, false)
        return
      }

      const params = JSON.stringify({
        number: '\'' + this.number + '\'',
        numberType: '\'' + this.numberType + '\''
      })
      this.isLoadingDuplicates = true
      const { data } = await axios.post('/api/core/NextNumberService/LoadPreExistingNumber', params)
      this.isLoadingDuplicates = false
      const preExistingNumber = data.Body
      this.startingSource = preExistingNumber.Source
      this.currentSource = preExistingNumber.Source

      if (this.number.length > 0) {
        this.fetchedNumbersBySource.set(this.currentSource, this.number)
      }
    },
    async switchNumberingSource () {
      const newSource = this.nextSource()
      const params = JSON.stringify({
        numberType: '\'' + this.numberType + '\'',
        newSource: '\'' + newSource + '\''
      })
      this.isLoadingDuplicates = true
      this.duplicates = []
      const { data } = await axios.post('/api/core/NextNumberService/SwitchNumberingSource', params)
      this.currentSource = data.Body
      this.isLoadingDuplicates = false
      if (this.isTransferPage) {
        this.$emit('userNumberGenerated')
      }
      await this.loadCurrentNumber()
    },
    async saveNewUserNumber () {
      const params = JSON.stringify({
        numberType: '\'' + this.numberType + '\'',
        number: '\'' + this.currentNumber + '\''
      })
      await axios.post('/api/core/NextNumberService/SaveUserNumber', params)
    },
    nextSource () {
      if (this.isUserNumber) {
        return 1
      } else {
        return 2
      }
    },
    async numberWasSaved () {
      const params = JSON.stringify({
        numberType: '\'' + this.numberType + '\'',
        numberSource: '\'' + this.currentSource + '\'',
        number: '\'' + this.currentNumber + '\''
      })
      await axios.post('/api/core/NextNumberService/NumberWasSaved', params)
    },
    async saveUsedNumber () {
      // Nothing to save. Just return.
      if (this.startingNumber === this.currentNumber && this.startingSource === this.currentSource) {
        return
      }

      // Save the new number
      if (this.isUserNumber && this.startingNumber !== this.currentNumber) {
        await this.saveNewUserNumber()
      }
      await this.numberWasSaved()
      this.startingNumber = this.currentNumber
    },
    showDuplicates () {
      this.$refs.confirmDuplicates.open()
    },
    async checkForDuplicates () {
      if (this.isUserNumber) {
        try {
          this.isLoadingDuplicates = true
          if (this.currentNumber && this.currentNumber.length !== undefined && this.currentNumber.length != 0) {
            const params = JSON.stringify({
              numberType: '\'' + this.numberType + '\'',
              number: '\'' + this.currentNumber + '\''
            })
            const { data } = await axios.post('/api/core/NextNumberService/OtherInstancesOfNumber', params)
            this.duplicates = data.Body
          } else {
            this.duplicates = []
          }
        } finally {
          this.isLoadingDuplicates = false
        }
      } else {
        this.duplicates = []
      }
      this.$emit('endingDuplicateCheck')
    },
    setCurrentNumber (number, emitNumberChanged) {
      this.currentNumber = number
      this.$emit('onNumberChanged', number)
      this.$emit('onIsValidChanged', this.isValid)
    },
    handleInput () {
      this.$emit('input')
      // Clear previous timeout
      clearTimeout(this.duplicateTimeout)
      // Set a new timeout
      this.$emit('startingDuplicateCheck')
      this.duplicateTimeout = setTimeout(() => {
        // Trigger duplicate check function after timeout
        this.checkForDuplicates()
      }, 2000)
    }
  },
  computed: {
    sourceText () {
      if (this.isSystemNumber) {
        return 'Switch to User Numbering'
      } else {
        return 'Switch to System Numbering'
      }
    },
    isSystemNumber () {
      return this.currentSource == 1
    },
    isUserNumber () {
      return this.currentSource == 2
    },
    isAllowUserNumbers () {
      return this.$store.getters.getAllowUserNumbers
    },
    isValid () {
      // Handling Blank Value
      if (this.currentNumber === '') {
        if (this.isAllowBarcodeBlank) {
          return true
        }
        if (!this.isRequired) {
          return true
        }
        return false
      }
      // Duplicate Checks
      if (this.allowDuplicates == 1) {
        return true
      }
      if (this.duplicates.length > 0) {
        return false
      }
      return true
    },
    allowDuplicates () {
      return this.$store.getters.orgSettings.AllowNumberingDuplicates
    }
  },
  watch: {
    async numberType (newVal) {
      if (newVal && newVal > 0) {
        this.fetchedNumbersBySource = new Map()
        await this.loadCurrentNumber()
      }
    },
    number (newVal) {
      this.currentNumber = newVal
    },
    currentNumber (newVal) {
      this.setCurrentNumber(newVal, true)
    },
    isValid () {
      this.$emit('onIsValidChanged', this.isValid)
    },
    isLoadingDuplicates () {
      if (this.isCatalogAddItem) {
        var isDisabled = false
        var isDuplicate = false
        if (this.duplicates.length > 0) {
          isDuplicate = true
        } else {
          isDuplicate = false
        }
        if (this.isLoadingDuplicates == false && isDuplicate) {
          isDisabled = true
        } else if (this.isLoadingDuplicates && !isDuplicate) {
          isDisabled = true
        }
        this.$emit('checkDuplicateNo', isDisabled)
      }
    }
  }
}
</script>
<style>
  .number-chooser.input-group {
    flex-wrap: inherit !important;
  }
  .number-chooser-spinner {
    border: 1px solid #E1E1E1;
  }
</style>
