const catalogProducts = {
  tableHeader: {
    desktop: [
      {
        label: '',
        key: 'MasterId',
        type: 'check'
      },
      {
        label: 'Categoría',
        key: 'Category',
        sortable: false
      },
      {
        label: 'Tipo',
        key: 'ItemType',
        sortable: false
      },
      {
        label: 'Fabricante',
        key: 'Manufacturer',
        sortable: true,
        type: String
      },
      {
        label: 'Descripción',
        key: 'Description',
        sortable: true,
        type: String
      },
      {
        label: 'Modelo',
        key: 'Model',
        sortable: false
      },
      {
        label: 'Id del Modelo',
        key: 'MasterNumber',
        sortable: false
      },
      {
        label: 'Rastreo',
        key: 'BrowserViewCount',
        sortable: false
      }
    ],
    mobile: [
      {
        label: '',
        key: 'check',
        type: 'check'
      },
      {
        label: 'Información',
        data: [
          {
            label: 'Categoría',
            key: 'Category',
            sortable: false
          },
          {
            label: 'Tipo',
            key: 'ItemType',
            sortable: false
          },
          {
            label: 'Modelo',
            key: 'Model',
            sortable: false
          },
          {
            label: 'Id del Modelo',
            key: 'MasterNumber',
            sortable: false
          },
          {
            label: 'Fabricante',
            key: 'Manufacturer',
            sortable: false
          },
          {
            label: 'Descripción',
            key: 'Description',
            sortable: false
          },
          {
            label: 'Seguimiento',
            key: 'BrowserViewCount',
            sortable: false
          }
        ]
      }
    ]
  }
}
export default catalogProducts
