const purchasing = {
  purchaseOrders: {
    title: 'Navegador de órdenes de compra',
    actionHeader: {
      0: {
        title: 'Crear Orden de Compra',
        icon: 'fas fa-plus'
      },
      1: {
        title: 'Recibir orden de Compra',
        icon: 'fas fa-shopping-basket'
      },
      2: {
        title: 'Imprimir',
        icon: 'fas fa-print'
      }
    },
    switcher: {
      switcherText: 'Orden de compra / Vista de artículo'
    },
    purchaseOrdersActions: [
      {
        title: 'Vista',
        actionName: 'onView',
        requiresSelection: true,
        allowsMultiSelect: false
      },
      {
        title: 'Borrar ',
        actionName: 'onDelete',
        requiresSelection: true,
        allowsMultiSelect: true
      }
    ],
    itemViewBrowserLineActions: [
      {
        title: 'Vista',
        actionName: 'onView',
        requiresSelection: true,
        allowsMultiSelect: false
      },
      {
        title: 'Borrar ',
        actionName: 'onItemDelete',
        requiresSelection: true,
        allowsMultiSelect: true
      },
      {
        title: 'Exportar',
        actionName: 'onExport',
        requiresSelection: true,
        allowsMultiSelect: true
      }
    ],
    labels: {
      browsePO: 'Examinar órden de compra',
      poNumber: 'Número de orden',
      orderDate: 'Fecha de orden',
      to: 'a',
      vendor: 'Vendedor',
      shipTo: 'Enviar',
      status: 'Estado',
      open: 'Abierta',
      closed: 'Cerrado',
      all: 'Todo',
      moreSearchOption: 'Más opciones de búsqueda...',
      fewerSearchOption: 'Menos opciones de búsqueda...',
      chargeTo: 'Cargos a',
      requisitionNo: 'No de solicitud',
      documentNo: 'Documento núm',
      expectedDate: 'Fecha esperada',
      unableToReceive: 'No disponible a recibir',
      waitingReceipts: 'Esta orden de compra aún no está esperando articualos a recibir',
      confirmReceive: 'Esta orden de compra está lista para ser recibida. ¿Le gustaría recibir contra esta orden de compra? Si o no',
      unableToDeleteItem: 'No se puede eliminar',
      warningDeleteItemLess: 'Algunos artículos seleccionados no se pueden eliminar, ya que esto daría como resultado que una o más órdenes de compra tuvieran cero artículos. Por favor cambie su selección para eliminar',
      warningDeleteItem: 'Los artículos solo se pueden eliminar de los borradores de órdenes de compra. Por favor cambia tu selección'
    },
    tableHeader: {
      desktop: [
        {
          label: '',
          key: 'OrderId',
          type: 'check'
        },
        {
          label: 'Fecha de orden',
          key: 'OrderDate',
          type: Date,
          sortable: 'true'
        },
        {
          label: 'OC No.',
          key: 'OrderNumber',
          sortable: 'true'
        },
        {
          label: 'Vendedor',
          key: 'VendorName',
          sortable: 'true'
        },
        {
          label: 'Cargar a',
          key: 'CostCenterDescription',
          sortable: 'true'
        },
        {
          label: 'Envar a',
          key: 'ShipToEntityDescription',
          sortable: 'true'
        },
        {
          label: 'Estado',
          key: 'Status',
          sortable: 'true'
        },
        {
          label: 'Expresar',
          key: 'State',
          sortable: 'true'
        },
        {
          label: 'Esperado',
          key: 'ExpectedDeliveryDate',
          type: Date,
          sortable: 'true'
        },
        {
          label: 'Solicitud',
          key: 'Requisition',
          sortable: 'true'
        },
        {
          label: 'Documento',
          key: 'DocumentNumber',
          sortable: 'true'
        },
        {
          label: ' ',
          key: 'action'
        }
      ],
      mobile: [
        {
          label: '',
          key: 'LineId',
          type: 'check'
        },
        {
          label: 'Info',
          data: [
            {
              label: 'Fecha de orden',
              key: 'OrderDate',
              type: Date,
              sortable: 'true'
            },
            {
              label: 'OC No.',
              key: 'OrderNumber',
              sortable: 'true'
            },
            {
              label: 'Vendedor',
              key: 'VendorName',
              sortable: 'true'
            },
            {
              label: 'Cargos a',
              key: 'CostCenterDescription',
              sortable: 'true'
            },
            {
              label: 'Enviar a',
              key: 'ShipToEntityDescription',
              sortable: 'true'
            },
            {
              label: 'Estado',
              key: 'Status',
              sortable: 'true'
            },
            {
              label: 'Expresar',
              key: 'State',
              sortable: 'true'
            },
            {
              label: 'Esperado',
              key: 'ExpectedDeliveryDate',
              type: Date,
              sortable: 'true'
            },
            {
              label: 'Solicitud',
              key: 'Requisition',
              sortable: 'true'
            },
            {
              label: 'Documento',
              key: 'DocumentNumber',
              sortable: 'true'
            }
          ]
        },
        {
          label: ' ',
          key: 'action'
        }
      ]
    },
    tableHeaderFlagDisabled: {
      desktop: [
        {
          label: 'Fecha de orden',
          key: 'OrderDate',
          type: Date,
          sortable: 'true'
        },
        {
          label: 'OC No.',
          key: 'OrderNumber',
          sortable: 'true'
        },
        {
          label: 'Vendedor',
          key: 'VendorName',
          sortable: 'true'
        },
        {
          label: 'Cargar a',
          key: 'CostCenterDescription',
          sortable: 'true'
        },
        {
          label: 'Envar a',
          key: 'ShipToEntityDescription',
          sortable: 'true'
        },
        {
          label: 'Estado',
          key: 'Status',
          sortable: 'true'
        },
        {
          label: 'Expresar',
          key: 'State',
          sortable: 'true'
        },
        {
          label: 'Esperado',
          key: 'ExpectedDeliveryDate',
          type: Date,
          sortable: 'true'
        },
        {
          label: 'Solicitud',
          key: 'Requisition',
          sortable: 'true'
        },
        {
          label: 'Documento',
          key: 'DocumentNumber',
          sortable: 'true'
        },
        {
          label: ' ',
          key: 'action'
        }
      ],
      mobile: [
        {
          label: 'Info',
          data: [
            {
              label: 'Fecha de orden',
              key: 'OrderDate',
              type: Date,
              sortable: 'true'
            },
            {
              label: 'OC No.',
              key: 'OrderNumber',
              sortable: 'true'
            },
            {
              label: 'Vendedor',
              key: 'VendorName',
              sortable: 'true'
            },
            {
              label: 'Cargos a',
              key: 'CostCenterDescription',
              sortable: 'true'
            },
            {
              label: 'Enviar a',
              key: 'ShipToEntityDescription',
              sortable: 'true'
            },
            {
              label: 'Estado',
              key: 'Status',
              sortable: 'true'
            },
            {
              label: 'Expresar',
              key: 'State',
              sortable: 'true'
            },
            {
              label: 'Esperado',
              key: 'ExpectedDeliveryDate',
              type: Date,
              sortable: 'true'
            },
            {
              label: 'Solicitud',
              key: 'Requisition',
              sortable: 'true'
            },
            {
              label: 'Documento',
              key: 'DocumentNumber',
              sortable: 'true'
            }
          ]
        },
        {
          label: ' ',
          key: 'action'
        }
      ]
    },
    itemViewTableHeader: {
      desktop: [
        {
          label: '',
          key: 'LineId',
          type: 'check'
        },
        {
          label: 'Número de OC',
          key: 'PONumber',
          sortable: 'true'
        },
        {
          label: 'Modelo',
          key: 'Model',
          sortable: 'true'
        },
        {
          label: 'Descripción',
          key: 'Description',
          sortable: 'true'
        },
        {
          label: 'Categoría',
          key: 'Category',
          sortable: 'true'
        },
        {
          label: 'Fabricante',
          key: 'Manufacturer',
          sortable: 'true'
        },
        {
          label: 'Cantidad',
          key: 'Qty',
          sortable: 'true'
        },
        {
          label: 'Costo',
          key: 'Cost',
          sortable: 'true'
        },
        {
          label: 'Costo Ext.',
          key: 'ExtCost',
          sortable: 'true'
        },
        {
          label: 'Proveedor',
          key: 'Vendor',
          sortable: 'true'
        },
        {
          label: 'Estado',
          key: 'Status',
          sortable: 'true'
        },
        {
          label: 'Fecha de Orden',
          key: 'OrderDate',
          type: Date,
          sortable: 'true'
        },
        {
          label: 'Fecha Esperada',
          key: 'ExpectedDate',
          type: Date,
          sortable: 'true'
        },
        {
          label: 'Enviar A',
          key: 'ShipTo',
          sortable: 'true'
        },
        {
          label: 'Cargar A',
          key: 'ChargeTo',
          sortable: 'true'
        },
        {
          label: 'Número de Documento',
          key: 'DocumentNumber',
          sortable: 'true'
        },
        {
          label: 'Número de Requisición',
          key: 'RequisitionNumber',
          sortable: 'true'
        },
        {
          label: ' ',
          key: 'actionItemView',
          isEditable: true
        }
      ],
      mobile: [
        {
          data: [
            {
              label: 'Número de OC',
              key: 'PONumber',
              sortable: 'true'
            },
            {
              label: 'Modelo',
              key: 'Model',
              sortable: 'true'
            },
            {
              label: 'Descripción',
              key: 'Description',
              sortable: 'true'
            },
            {
              label: 'Categoría',
              key: 'Category',
              sortable: 'true'
            },
            {
              label: 'Fabricante',
              key: 'Manufacturer',
              sortable: 'true'
            },
            {
              label: 'Cantidad',
              key: 'Qty',
              sortable: 'true'
            },
            {
              label: 'Costo',
              key: 'Cost',
              sortable: 'true'
            },
            {
              label: 'Costo Ext.',
              key: 'ExtCost',
              sortable: 'true'
            },
            {
              label: 'Proveedor',
              key: 'Vendor',
              sortable: 'true'
            },
            {
              label: 'Estado',
              key: 'Status',
              sortable: 'true'
            },
            {
              label: 'Fecha de Orden',
              key: 'OrderDate',
              type: Date,
              sortable: 'true'
            },
            {
              label: 'Fecha Esperada',
              key: 'ExpectedDate',
              type: Date,
              sortable: 'true'
            },
            {
              label: 'Enviar A',
              key: 'ShipTo',
              sortable: 'true'
            },
            {
              label: 'Cargar A',
              key: 'ChargeTo',
              sortable: 'true'
            },
            {
              label: 'Número de Documento',
              key: 'DocumentNumber',
              sortable: 'true'
            },
            {
              label: 'Número de Requisición',
              key: 'RequisitionNumber',
              sortable: 'true'
            }
          ]
        },
        {
          label: ' ',
          key: 'actionItemView',
          isEditable: true
        }
      ]
    }
  },
  PODetail: {
    title: 'Detalle de la orden de compra',
    POStatus: {
      0: 'Abierta',
      1: 'Cerrada',
      2: 'Borrador',
      3: 'Esperando recibos',
      4: 'Hecho de recibir',
      5: 'Completa',
      6: 'Incompleta',
      7: 'Cancelada'
    },
    actionHeader: {
      save: {
        title: 'Salvar',
        icon: 'fas fa-save'
      },
      print: {
        title: 'Imprimir',
        icon: 'fas fa-print'
      },
      addTemplate: {
        title: 'Agregar Plantilla',
        icon: 'fas fa-plus'
      },
      addToolRecord: {
        title: 'Agregar registro de herramienta',
        icon: 'fas fa-plus'
      },
      addMaterialRecord: {
        title: 'Agregar registro de material',
        icon: 'fas fa-plus'
      },
      attachments: {
        title: 'Archivos adjuntos',
        icon: 'fas fa-paperclip'
      },
      addItem: {
        title: 'Añadir artículo',
        icon: 'fas fa-plus'
      },
      deleteItem: {
        title: 'Eliminar elemento',
        icon: 'fas fa-x'
      },
      clearPODraft: {
        title: 'Borrar Borrador',
        icon: 'fa fa-trash'
      }
    },
    actionHeaderCreate: {
      save: {
        title: 'Salvar',
        icon: 'fas fa-save'
      },
      addTemplate: {
        title: 'Agregar Plantilla',
        icon: 'fas fa-plus'
      },
      addToolRecord: {
        title: 'Agregar registro de herramienta',
        icon: 'fas fa-plus'
      },
      addMaterialRecord: {
        title: 'Agregar registro de material',
        icon: 'fas fa-plus'
      },
      attachments: {
        title: 'Archivos adjuntos',
        icon: 'fas fa-paperclip'
      },
      addItem: {
        title: 'Añadir artículo',
        icon: 'fas fa-plus'
      },
      deleteItem: {
        title: 'Eliminar elemento',
        icon: 'fas fa-x'
      },
      clearPODraft: {
        title: 'Borrar Borrador',
        icon: 'fa fa-trash'
      }
    },
    labels: {
      po: 'Orden de compra',
      poSummary: 'Resumen de detalles de la orden de compra',
      vendor: 'Vendedor',
      shipTo: 'Enviar a',
      chargeToNotes: 'Cargar a notas',
      costCenter: 'Centro de costos',
      costCode: 'Código de costo',
      orderedBy: 'Ordenado por',
      requisition: 'Solicitud',
      shipVia: 'Embarcar vía',
      orderDate: 'Fecha de orden',
      expectedDate: 'Fecha esperada',
      documentNo: 'Documento núm',
      items: 'Elementos',
      subTotal: 'Sub - Total',
      shipping: 'Envio',
      tax: 'Impuesto',
      total: 'Total',
      comments: 'Comentarios',
      recordInformation: 'Información de registro',
      createdOn: 'Creado en',
      createdBy: 'Creado por',
      modifiedOn: 'Modificado en',
      modifiedBy: 'Modificado por',
      status: 'Estado',
      lastReceivedOn: 'Recibido por última vez el',
      lastReceivedBy: 'Recibido por última vez por',
      onlyItemForVendor: 'Solo artículos para este vendedor',
      allItems: 'Todos los artículos',
      poTicket: 'Ticket de orden de compra',
      itemsInPOTicket: 'Elementos en el ticket de orden de compra',
      itemDetail: 'Detalle del artículo',
      itemNo: 'Artículo No.',
      itemDesc: 'Descripción del Artículo',
      sku: 'SKU',
      qty: 'Cant.',
      cost: 'Costo',
      unitMeasure: 'Unidad de medida',
      chargeTo: 'Cargar a'
    },
    dialogLabels: {
      unableSave: 'No se puede guardar...',
      noVendor: 'Seleccione un proveedor antes de guardar. Si no existe ningún proveedor, créelo.',
      noShip: 'Seleccione un Enviar a antes de guardar. Si no existe Enviar a, créelos.',
      noItem: 'Debes tener al menos un artículo comprado antes de guardar.',
      changeStatus: 'Cambiar Estado...',
      isReadyForPurchase: '¿Este pedido está listo para su compra?',
      changeCostsTitle: 'ToolWatch',
      changeCostsMsg: '¿Le gustaría actualizar los costos de artículos para {description}?',
      mustNumberAllRecords: 'Debe numerar todos los registros.',
      deleteLineTitle: 'Borrar',
      deleteLineItem: '¿Estás seguro de que quieres eliminar este artículo?',
      undeleteLineTitle: 'Seleccione un elemento para eliminar.',
      successDelete: 'Borrado exitosamente.',
      receiveWarning: ' No permitir que se cree un ticket de recepción. Debe seleccionar cualquier cantidad en cualquier artículo de línea'
    },
    tabs: [
      'Artículos de orden de compra',
      'Ingresos',
      'Info de Registros'
    ],
    poItemsTabTableHeader: {
      desktop: [
        {
          label: 'ID de modelo',
          key: 'ItemNumber',
          sortable: 'true'
        },
        {
          label: 'T',
          key: 'ItemType',
          sortable: 'true'
        },
        {
          label: 'Fabricante',
          key: 'Manufacturer',
          sortable: 'true'
        },
        {
          label: 'Modelo',
          key: 'Model',
          sortable: 'true'
        },
        {
          label: 'Descripción del Artículo',
          key: 'Description',
          sortable: 'true'
        },
        {
          label: 'SKU',
          key: 'Sku',
          sortable: 'true',
          editable: true
        },
        {
          label: 'Cantidad',
          key: 'QuantityOrdered',
          sortable: 'true',
          editable: true
        },
        {
          label: 'Recibida',
          key: 'QuantityReceived',
          sortable: 'true'
        },
        {
          label: 'Cancelada',
          key: 'QuantityCancelled',
          sortable: 'true',
          editable: true
        },
        {
          label: 'Costo',
          key: 'Cost',
          sortable: 'true',
          editable: true
        },
        {
          label: 'UOM',
          key: 'UomDescription',
          sortable: 'true',
          editable: true
        },
        {
          label: 'Extendido',
          key: 'ExtendedCosts',
          type: 'Currency',
          sortable: 'true'
        },
        {
          label: 'Cerrado',
          key: 'isClosed',
          type: Boolean,
          sortable: 'true'
        },
        {
          label: 'Centro de costos',
          key: 'CostCenterId',
          sortable: 'true'
        },
        {
          label: 'Código de costo',
          key: 'CostCodeId',
          sortable: 'true'
        },
        {
          label: 'Factura',
          key: 'Bill',
          type: Boolean,
          sortable: 'true'
        }
      ],
      mobile: [
        {
          label: 'Información',
          data: [
            {
              label: 'ID de modelo',
              key: 'Number',
              sortable: 'true'
            },
            {
              label: 'T',
              key: 'ItemType',
              sortable: 'true'
            },
            {
              label: 'Fabricante',
              key: 'Manufacturer',
              sortable: 'true'
            },
            {
              label: 'Modelo',
              key: 'Model',
              sortable: 'true'
            },
            {
              label: 'Descripción del Artículo',
              key: 'Description',
              sortable: 'true'
            },
            {
              label: 'SKU',
              key: 'Sku',
              sortable: 'true'
            },
            {
              label: 'Cantidad',
              key: 'QuantityOrdered',
              sortable: 'true',
              editable: true
            },
            {
              label: 'Recibido',
              key: 'QuantityReceived',
              sortable: 'true'
            },
            {
              label: 'Cancelado',
              key: 'QuantityCancelled',
              sortable: 'true',
              editable: true
            },
            {
              label: 'Costo',
              key: 'Cost',
              type: 'Currency',
              sortable: 'true'
            },
            {
              label: 'UOM',
              key: 'UomDescription',
              sortable: 'true'
            },
            {
              label: 'Extendido',
              key: 'ExtendedCosts',
              type: 'Currency',
              sortable: 'true'
            },
            {
              label: 'Cerrada',
              key: 'isClosed',
              type: Boolean,
              sortable: 'true'
            },
            {
              label: 'Centro de costos',
              key: 'CostCenterId',
              sortable: 'true'
            },
            {
              label: 'Código de costo',
              key: 'CostCodeId',
              sortable: 'true'
            },
            {
              label: 'Factura',
              key: 'Bill',
              type: Boolean,
              sortable: 'true'
            }
          ]
        },
        {
          label: '',
          key: 'poItemAction'
        }
      ]
    },
    receiptsTabTableHeader: {
      desktop: [
        {
          label: 'ID de modelo',
          key: 'Number',
          sortable: 'true'
        },
        {
          label: 'Descripción del Artículo',
          key: 'Description',
          sortable: 'true'
        },
        {
          label: 'Recibida',
          key: 'QuantityReceived',
          sortable: 'true'
        },
        {
          label: 'Orden Recibida',
          key: 'ReceiptNumber',
          sortable: 'true'
        },
        {
          label: 'Recibido el',
          key: 'ReceivedOn',
          sortable: 'true'
        },
        {
          label: 'Recibido en',
          key: 'ReceivedAtLocation',
          sortable: 'true'
        },
        {
          label: 'Recibido por',
          key: 'ReceivedByEntity',
          sortable: 'true'
        }
      ],
      mobile: [
        {
          label: 'Información',
          data: [
            {
              label: 'ID de modelo',
              key: 'Number',
              sortable: 'true'
            },
            {
              label: 'Descripción del Artículo',
              key: 'Description',
              sortable: 'true'
            },
            {
              label: 'Recibido',
              key: 'QuantityReceived',
              sortable: 'true'
            },
            {
              label: 'Recibiendo boleto',
              key: 'ReceiptNumber',
              sortable: 'true'
            },
            {
              label: 'Recibido el',
              key: 'ReceivedOn',
              sortable: 'true'
            },
            {
              label: 'Recibido en',
              key: 'ReceivedAtLocation',
              sortable: 'true'
            },
            {
              label: 'Recibido por',
              key: 'ReceivedByEntity',
              sortable: 'true'
            }
          ]
        }
      ]
    },
    poDetailAddTemplateTableHeader: {
      desktop: [
        {
          label: '',
          key: 'Id',
          type: 'check',
          headerCheckboxVisible: false
        },
        {
          label: 'Tipo',
          key: 'TemplateType',
          sortable: true,
          type: String
        },
        {
          label: 'Categoría',
          key: 'Category',
          sortable: true,
          type: String
        },
        {
          label: 'ID',
          key: 'Number',
          sortable: true.valueOf,
          type: String
        },
        {
          label: 'Descripción',
          key: 'Description',
          sortable: true,
          type: String
        }
      ],
      mobile: [
        {
          label: '',
          key: 'Id',
          type: 'check'
        },
        {
          label: 'Info',
          data: [
            {
              label: 'Tipo',
              key: 'TemplateType',
              sortable: true,
              type: String
            },
            {
              label: 'Categoría',
              key: 'Category',
              sortable: true,
              type: String
            },
            {
              label: 'ID',
              key: 'Number',
              sortable: true.valueOf,
              type: String
            },
            {
              label: 'Descripción',
              key: 'Description',
              sortable: true,
              type: String
            }
          ]
        }
      ]
    },
    poDetailAddToolRecordTableHeader: {
      desktop: [
        {
          label: '',
          key: 'Id',
          type: 'check',
          headerCheckboxVisible: false
        },
        {
          label: 'Categoría',
          key: 'Category',
          sortable: true,
          type: String
        },
        {
          label: 'Descripción',
          key: 'Description',
          sortable: true,
          type: String
        },
        {
          label: 'Fabricante',
          key: 'Manufacturer',
          sortable: true,
          type: String
        },
        {
          label: 'Modelo',
          key: 'Model',
          sortable: true,
          type: String
        },
        {
          label: 'ID de modelo',
          key: 'Number',
          sortable: true,
          type: String
        }
      ],
      mobile: [
        {
          label: '',
          key: 'Id',
          type: 'check'
        },
        {
          label: 'Info',
          data: [
            {
              label: 'Categoría',
              key: 'Category',
              sortable: true,
              type: String
            },
            {
              label: 'Descripción',
              key: 'Description',
              sortable: true,
              type: String
            },
            {
              label: 'Fabricante',
              key: 'Manufacturer',
              sortable: true,
              type: String
            },
            {
              label: 'Modelo',
              key: 'Model',
              sortable: true,
              type: String
            },
            {
              label: 'ID de modelo',
              key: 'Number',
              sortable: true,
              type: String
            }
          ]
        }
      ]
    }
  },
  receivingTicket: {
    title: 'Navegador de recepción',
    labels: {
      0: 'Recibiendo boleto',
      1: 'Fecha recibida',
      2: 'a',
      3: 'Vendedor',
      4: 'Recibido en',
      5: 'Menos opciones de búsqueda...',
      6: 'Más opciones de búsqueda...',
      7: 'Cargar a',
      8: 'OC. Número',
      9: 'Solicitud No.',
      10: 'Documento No',
      11: 'Fecha esperada'
    },
    tableHeader: {
      desktop: [
        {
          label: 'Rec. No',
          key: 'ReceiptNumber',
          sortable: 'true'
        },
        {
          label: 'Recibido',
          key: 'ReceivedOn',
          type: Date,
          sortable: 'true'
        },
        {
          label: 'Recibido En',
          key: 'ReceivedAtDescription',
          sortable: 'true'
        },
        {
          label: 'Recibido por',
          key: 'ReceivedByDescription',
          sortable: 'true'
        },
        {
          label: 'Vendedor',
          key: 'VendorName',
          sortable: 'true'
        },
        {
          label: 'Estado',
          key: 'State',
          sortable: 'true'
        },
        {
          label: 'OC No.',
          key: 'PONumber',
          sortable: 'true'
        },
        {
          label: 'Cargar a',
          key: 'ReceivedAtDescription',
          sortable: 'true'
        },
        {
          label: 'Fecha esperada',
          key: 'ExpectedDeliveryDate',
          type: Date,
          sortable: 'true'
        },
        {
          label: 'Solicitud',
          key: 'Requisition',
          sortable: 'true'
        },
        {
          label: 'Documento',
          key: 'DocumentNumber',
          sortable: 'true'
        },
        {
          label: '',
          key: 'receivingTicketAction'
        }
      ],
      mobile: [
        {
          label: 'Info',
          data: [
            {
              label: 'Rec. No',
              key: 'ReceiptNumber',
              sortable: 'true'
            },
            {
              label: 'Recibido',
              key: 'ReceivedOn',
              type: Date,
              sortable: 'true'
            },
            {
              label: 'Recibido En',
              key: 'ReceivedAtDescription',
              sortable: 'true'
            },
            {
              label: 'Recibido por',
              key: 'ReceivedByDescription',
              sortable: 'true'
            },
            {
              label: 'Vendedor',
              key: 'VendorName',
              sortable: 'true'
            },
            {
              label: 'Estado',
              key: 'State',
              sortable: 'true'
            },
            {
              label: 'OC No.',
              key: 'PONumber',
              sortable: 'true'
            },
            {
              label: 'Cargar a',
              key: 'ReceivedAtDescription',
              sortable: 'true'
            },
            {
              label: 'Fecha esperada',
              key: 'ExpectedDeliveryDate',
              type: Date,
              sortable: 'true'
            },
            {
              label: 'Solicitud',
              key: 'Requisition',
              sortable: 'true'
            },
            {
              label: 'Documento',
              key: 'DocumentNumber',
              sortable: 'true'
            }
          ]
        },
        {
          label: '',
          key: 'receivingTicketAction'
        }
      ]
    }
  },
  receivingTicketDetail: {
    title: 'Recibiendo boleto',
    actionHeader: {
      checkAll: {
        title: 'Comprobar todo',
        icon: 'fas fa-check-square'
      },
      unCheckAll: {
        title: 'Desmarcar todo',
        icon: 'fas fa-square'
      },
      print: {
        title: 'Impresión',
        icon: 'fas fa-print'
      },
      attachments: {
        title: 'Archivos adjuntos',
        icon: 'fas fa-paperclip'
      }
    },
    labels: {
      poInfo: 'Información de la orden de compra',
      poNo: 'OC No.',
      requisitionNo: 'Solicitud No.',
      documentNo: 'Documento No.',
      vendor: 'Vendedora',
      receivingInfo: 'Recibir información',
      receivedBy: 'Recibido por',
      receivedDate: 'Fecha recibida',
      receivingDocument: 'Recibiendo Documento',
      receivedAt: 'Recibido en',
      note: 'Nota',
      signature: 'Firma',
      viewPO: 'Vista OC...',
      receivingTicketSummary: 'Recibiendo el resumen del ticket',
      newToolWizard: 'Nuevo asistente de herramientas...',
      newToolsPending: 'Nuevas herramientas pendientes',
      newToolWizardNote1: 'Ingrese los números de herramienta que desea asignar a las nuevas herramientas, para que puedan agregarse a su sistema ToolWatch. También puede ingresar el código de barras y los números de serie en este momento; sin embargo, no son necesarios.',
      newToolWizardNote2: 'Después de hacer clic en "Crear herramientas", las nuevas herramientas se agregarán a la base de datos del sistema ToolWatch.',
      autoNumberTools: 'Herramientas de numeración automática'
    },
    tableHeader: {
      desktop: [
        {
          label: 'Id del modelo',
          key: 'Number',
          sortable: 'true'
        },
        {
          label: 'T',
          key: 'ItemType',
          sortable: true
        },
        {
          label: 'Fabricante',
          key: 'Manufacturer',
          sortable: 'true'
        },
        {
          label: 'Modelo',
          key: 'Model',
          sortable: 'true'
        },
        {
          label: 'Descripción del Artículo',
          key: 'Description',
          sortable: 'true'
        },
        {
          label: 'SKU',
          key: 'SKU',
          sortable: 'true'
        },
        {
          label: 'Restante',
          key: 'QuantityOrdered',
          sortable: 'true'
        },
        {
          label: '',
          key: 'received',
          isEditable: true,
          type: Boolean,
          sortable: 'true'
        },
        {
          label: 'Recibido',
          key: 'QuantityReceived',
          sortable: 'true',
          editable: true
        },
        {
          label: 'Precio actual',
          key: 'ActualPrice',
          sortable: 'true'
        },
        {
          label: 'UOM',
          key: 'UomDescription',
          sortable: 'true'
        },
        {
          label: 'Costo extendido',
          key: 'ExtendedCost',
          type: 'Currency',
          sortable: 'true'
        }
      ],
      mobile: [
        {
          label: 'Info',
          data: [
            {
              label: 'Id del modelo',
              key: 'Number',
              sortable: 'true'
            },
            {
              label: 'T',
              key: 'ItemType',
              sortable: true
            },
            {
              label: 'Fabricante',
              key: 'Manufacturer',
              sortable: 'true'
            },
            {
              label: 'Modelo',
              key: 'Model',
              sortable: 'true'
            },
            {
              label: 'Descripción del Artículo',
              key: 'Description',
              sortable: 'true'
            },
            {
              label: 'SKU',
              key: 'SKU',
              sortable: 'true'
            },
            {
              label: 'Restante',
              key: 'QuantityOrdered',
              sortable: 'true'
            },
            {
              label: '',
              key: 'received',
              isEditable: true,
              type: Boolean,
              sortable: 'true'
            },
            {
              label: 'Recibida',
              key: 'QuantityReceived',
              sortable: 'true',
              editable: true
            },
            {
              label: 'Precio actual',
              key: 'ActualPrice',
              sortable: 'true'
            },
            {
              label: 'UOM',
              key: 'UomDescription',
              sortable: 'true'
            },
            {
              label: 'Costo extendido',
              key: 'ExtendedCost',
              type: 'Currency',
              sortable: 'true'
            }
          ]
        }
      ]
    },
    newToolWizardTableHeader: {
      desktop: [
        {
          label: 'Número de herramienta permanente',
          key: 'toolWizardToolNumber'
        },
        {
          label: 'código de barras',
          key: 'toolWizardBarCode'
        },
        {
          label: 'Número de serie',
          key: 'toolWizardSerialNumber'
        },
        {
          label: 'Descripción del Artículo',
          key: 'Description'
        },
        {
          label: 'Fabricante',
          key: 'Manufacturer'
        },
        {
          label: 'Número de modelo',
          key: 'Model'
        }
      ],
      mobile: [
        {
          label: 'Info',
          data: [
            {
              label: 'Número de herramienta permanente',
              key: 'toolWizardToolNumber'
            },
            {
              label: 'código de barras',
              key: 'toolWizardBarCode'
            },
            {
              label: 'Número de serie',
              key: 'toolWizardSerialNumber'
            },
            {
              label: 'Descripción del Artículo',
              key: 'Description'
            },
            {
              label: 'Fabricante',
              key: 'Manufacturer'
            },
            {
              label: 'Número de modelo',
              key: 'Model'
            }
          ]
        }
      ]
    }
  }
}

export default purchasing
