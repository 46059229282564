const toolLists = {
  tableHeader: {
    desktop: [
      {
        label: 'Distribución a granel',
        key: 'bulkDistribution',
        sortable: true,
        isQuantity: true
      },
      {
        label: 'Cant',
        key: 'quantity',
        sortable: true,
        isQuantity: true
      },
      {
        label: 'ID del artículo',
        key: 'number',
        sortable: true,
        isQuantity: false
      },
      {
        label: 'Código de barras',
        key: 'barcode',
        sortable: true,
        isQuantity: false
      },
      {
        label: 'Número de serie',
        key: 'serial',
        sortable: true,
        isQuantity: false
      },
      {
        label: 'Estado',
        key: 'status',
        sortable: true,
        isQuantity: false
      },
      {
        label: 'Asignado a',
        key: 'assignedTo',
        sortable: true,
        isQuantity: false
      },
      {
        label: 'Propietario',
        key: 'owner',
        sortable: true
      },
      {
        label: 'Gerente',
        key: 'manager',
        sortable: true
      }
    ],
    mobile: [
      {
        label: 'Distribución a granel',
        key: 'bulkDistribution',
        sortable: true,
        isQuantity: true
      },
      {
        label: 'ID del artículo',
        key: 'number',
        sortable: true,
        isQuantity: false
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Cant',
            key: 'quantity',
            sortable: true,
            isQuantity: true
          },
          {
            label: 'Código de barras',
            key: 'barcode',
            sortable: true,
            isQuantity: false
          },
          {
            label: 'Número de serie',
            key: 'serial',
            sortable: true,
            isQuantity: false
          },
          {
            label: 'Estado',
            key: 'status',
            sortable: true,
            isQuantity: false
          },
          {
            label: 'Asignado a',
            key: 'assignedTo',
            sortable: true,
            isQuantity: false
          },
          {
            label: 'Propietario',
            key: 'owner',
            sortable: true,
            isQuantity: false
          },
          {
            label: 'Gerente',
            key: 'manager',
            sortable: true,
            isQuantity: false
          }
        ]
      }
    ]
  }
}

export default toolLists
