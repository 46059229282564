// TG 3/28/24
// Use the components/gridRows/sharedRows folder for column templates whereever possible.
// Adding columns in shared rows will allow for reuse and greater consistiancy across the Cloud application
// A newly added grid might be able to created from existing column templates. The inventory of columns
// for each page is in in the locals folder jave script files and reference the header data in the sharedGridRows.vue file.

<template>
  <div class="tw-grid-container">
    <div class="footable-holder" :style="cssVariables">
      <table
        class="footable table toggle-arrow-tiny"
        :class="tableColumnWrapClass"
      >
        <TWGridHeader
          :tableHeader="mergedHeader"
          :isCheckedAll="isCheckedAll"
          :isCheckedAllWithKey="isCheckedAllWithKey"
          :isSortable="isSortable"
          :showFilters="showFilters"
          :tableFilter="tableFilter"
          v-on="$listeners"
          @onSort="desktopSortBy"
          @onGridHeaderDataFilter="onDataFilter"
        />
        <component
          :is="rowComponentData.component"
          infinite-wrapper
          v-bind="rowComponentData.props"
          v-on="$listeners"
          :tableHeader="mergedHeader"
          :data="scrollData"
          :selectedRow="selectedRow"
        />
      </table>
      <infinite-loading
        ref="gridInfiniteLoader"
        @infinite="infiniteHandler"
        spinner='waveDots'
        @distance="1"
      >
        <template slot="no-more"> {{scrollData.length>defaultPageSize ? $t('message.noMoreData') : ' '}}</template>
        <template v-if="emptyTableMessage != ''" slot="no-results">{{scrollData.length == 0 ? $t('message.noResultSearchSuggestion') : ''}}</template>
        <template v-else slot="no-results">{{scrollData.length == 0 ? $t('message.noResult') : ''}}</template>
      </infinite-loading>
    </div>
    <div class="tw-grid-total-rows" :class="totalRowsPositionRelative ? 'position-relative' : ''">
      <TWGridColumnsSetting
      v-if="showGearIcon"
      @GridColumnsDisplay="onColumnsDisplay"
      @resetGridColumns="resetGridColumns"
      @resetToDefault="reset"
      :additionalSettings="additionalSettings"
      @additionalSettingsCallback="additionalSettingsCallback"
      />
      {{$t('admin.pagingLabel.0')}} {{data.length}} {{$t('admin.pagingLabel.3')}}
    </div>
    <div class="tw-shipping-modal" v-if="isColumnsDisplaySetting" id="columnsDisplaySetting" tabindex="-1" role="dialog"
      data-backdrop="static">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">{{$t('twGridColumnsDisplay.columnsSetting.panelLabel.modalTitle')}}</h4>
            <button type="button" class="close" aria-label="Close" @click="cancel" :disabled="saving">
              <span aria-hidden="true" style="vertical-align: middle;">&times;</span>
            </button>
          </div>
          <div class="modal-body py-2">
            <div v-if="columns">
              <div class="row">
                <div class="col px-0">
                  <h4>{{$t('twGridColumnsDisplay.columnsSetting.panelLabel.l_label')}}:</h4>
                </div>
                <div class="col-1 px-1" />
                <div class="col px-0">
                  <h4>{{$t('twGridColumnsDisplay.columnsSetting.panelLabel.r_label')}}:</h4>
                </div>
              </div>
              <div class="row">
                <div class="col px-0">
                  <table class="favorite-manage form-control" style="display:block;overflow:auto;border-collapse:separate">
                    <tr class="manageColumnLeft cell"
                    v-for="(column, index) in columnsOptions" :key="column.key" :value="column.key"
                    @mousedown ="selectedRowHighlight(index, 'manageColumnLeft')"
                    ><td style="width:50%" >{{ column.label }}</td></tr>
                  </table>
                </div>
                <div class="col-1 px-1 align-self-center">
                  <div class="d-flex justify-content-center">
                    <button type="button" class="settingbuttons text-dark p-1" @click="moveRight" :disabled="saving">
                      <i class="material-icons" style="font-size: 18px; color: white;
                       vertical-align: middle;">arrow_forward</i>
                    </button>
                  </div>
                  <div class="d-flex justify-content-center">
                    <button type="button" class="settingbuttons text-dark p-1 mt-2" @click="moveLeft" :disabled="saving">
                      <i class="material-icons" style="font-size: 18px; color: white;
                       vertical-align: middle;">arrow_back</i>
                    </button>
                  </div>
                </div>
                <div class="col px-0">
                  <table class="favorite-manage form-control" style="display:block;overflow:auto;border-collapse:separate">
                    <tr class="manageColumnRight cell"
                    v-for="(column, index) in potentialDisplayColumns" :key="column.key" :value="column.key"
                    @mousedown ="selectedRowHighlight(index, 'manageColumnRight')"
                    ><td style="width:50%" >{{ column.label }}</td></tr>
                  </table>
                </div>
                <div class="col-1 px-1 align-self-center">
                  <div class="d-flex justify-content-center">
                    <button type="button" class="settingbuttons text-dark p-1" @click="moveUp" :disabled="saving">
                      <i class="material-icons" style="font-size: 18px; color: white;
                       vertical-align: middle;">arrow_upward</i>
                    </button>
                  </div>
                  <div class="d-flex justify-content-center">
                    <button type="button" class="settingbuttons text-dark p-1 mt-2" @click="moveDown" :disabled="saving">
                      <i class="material-icons" style="font-size: 18px; color: white;
                       vertical-align: middle;">arrow_downward</i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="d-flex justify-content-center">
                <div class="spinner-border text-warning" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <b class="pl-2r">
              {{ $t('twGridColumnsDisplay.switch.text') }}
            </b>
            <b>{{ $t('twGridColumnsDisplay.switch.off') }}</b>
            <ToggleSwitch
              :isActive="wrapColumnText"
              :activeColor="primaryColor"
              @onActiveChanged="onTextWrappingSwitchChanged">
            </ToggleSwitch>
            <b class="pl-5r">
              {{ $t('twGridColumnsDisplay.switch.on') }}
            </b>
            <button type="button" class="settingbuttons btn close-btn"  @click="cancel" :disabled="saving">
              {{ $t('twGridColumnsDisplay.buttons.btncancel') }}
            </button>
            <button type="button" class="settingbuttons btn" @click="storeColumnsData">
              {{ $t('twGridColumnsDisplay.buttons.change') }}
              <span v-if="saving" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from 'jquery'
import InfiniteLoading from 'vue-infinite-loading'
import TWGridHeader from '../shared/TWGridHeader.vue'
import {
  deepCopy,
  mergeObjects,
  successHandler,
  applyFiltersOnGrid,
  sortingFunc,
  applyAdditionalColumnFilters
} from '../../helpers/utils'
import TWGridColumnsSetting from './TWGridColumnsSetting.vue'
import ModalDialog from '../shared/ModalDialog.vue'
import { useLocalUserStorage } from '../../composables/useLocalUserStorage'
import ToggleSwitch from '../shared/ToggleSwitch'

export default {
  components: {
    InfiniteLoading,
    TWGridHeader,
    TWGridColumnsSetting,
    ModalDialog,
    ToggleSwitch
  },
  name: 'TWGrid',
  setup () {
    // Return the full object
    const localUserStorage = useLocalUserStorage()
    return { localUserStorage }
  },
  props: {
    tableData: {
      type: Array,
      required: true,
      default: function () {
        return []
      }
    },
    pathToHeader: {
      type: String,
      required: true
    },
    rowComponentData: {
      type: Object,
      required: true
    },
    showFilters: {
      type: Boolean,
      required: true
    },
    showGearIcon: {
      type: Boolean,
      default: false
    },
    hasFilters: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    additionalSettings: {
      type: Object,
      default: null
    },
    scrollSize: {
      type: Number,
      default: 250
    },
    canSelectRows: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    totalRowsPositionRelative: {
      type: Boolean,
      default: false
    },
    tableFilters: {
      type: Object,
      default: function () {
        return {}
      }
    },
    emptyTableMessage: {
      type: String,
      default: '',
      required: false
    }
  },
  data () {
    return {
      tableFilter: {},
      isSortable: [],
      preSelectedId: 0,
      pageSize: 0,
      defaultLoadData: 250,
      defaultPageSize: 250,
      selectedRow: null,
      displayColumns: null,
      potentialDisplayColumns: null,
      columns: null,
      columnsOptions: null,
      selectedLeft: [],
      selectedRight: [],
      saving: false,
      loading: false,
      isMobile: false,
      data: this.tableData,
      wrapColumnText: false,
      tableColumnWrapClass: 'table-nowraptext',
      mobileHeader: [],
      mobileColumnFirst: null,
      lastSelectedRow: undefined,
      tableRows: [],
      isColumnsDisplaySetting: false
    }
  },
  beforeMount () {
    this.defaultPageSize = this.scrollSize
    this.defaultDataLength = this.scollSize
    this.setWrapColumnValue()
  },
  methods: {

    reset () {
      this.localUserStorage.removeData(this.getTableName)
      this.resetGridColumns()
      this.$emit('onRefresh')
    },

    getSortable () {
      this.mergedHeader.desktop.forEach((data) => {
        const cell = {
          sorted: data.sorted ?? false,
          asc: data.asc ?? false
        }
        this.isSortable.push(cell)
      })
    },
    infiniteHandler ($state) {
      const scrollData = this.data.slice(this.pageSize, this.defaultLoadData)
      if (scrollData.length > 0) {
        this.pageSize = this.pageSize + this.defaultPageSize
        this.defaultLoadData = this.defaultLoadData + this.defaultPageSize
        $state.loaded()
      } else {
        $state.complete()
      }
    },
    onDataFilter () {
      const checkBoxAvailable = this.mergedHeader.desktop[0]
      if (this.headerCheckboxVisible || this.canSelectRows ||
        (checkBoxAvailable &&
          checkBoxAvailable?.type === 'check' &&
          checkBoxAvailable?.headerCheckboxVisible === false)) {
        const obj = { tableFilter: this.tableFilter, mergedHeader: this.mergedHeader.desktop }
        this.$emit('onTWGridDataFilter', obj)
      } else {
        this.data = this.tableData
        this.applyFilters()
      }
    },
    applyFilters () {
      this.data = applyFiltersOnGrid(
        this.data,
        this.mergedHeader.desktop,
        this.tableFilter,
        this.$i18n.locale)
    },
    desktopSortBy (obj) {
      this.$store.commit('setLoading', true)
      this.isSortable.forEach((value) => {
        value.sorted = false
      })
      const key = obj.key
      const type = obj.type
      const index = obj.index
      this.isSortable[index].sorted = true
      this.isSortable[index].asc = !this.isSortable[index].asc
      this.isSortable = [...this.isSortable]
      this.preSelectedId = index
      sortingFunc(this.data, { key, type, asc: this.isSortable[index].asc })
      this.$store.commit('setLoading', false)
      this.$emit('onSortBy', { key, type, asc: this.isSortable[index].asc })
    },
    async getColumns () {
      this.columns = null
      try {
        if (this.isMobile) {
          this.mobileColumnFirst = this.chosenLanguageHeader.mobile.find(() => true)
          this.columns = this.chosenLanguageHeader.mobile.filter(x => x.label.trim() !== '')
          this.mobileHeader = this.columns
          const mobileCols = this.chosenLanguageHeader.mobile.filter(x => x.data != undefined)[0]
          this.columns = mobileCols?.data?.filter(x => x.label.trim() !== '')
        } else {
          this.columns = this.chosenLanguageHeader.desktop.filter(x => x.label.trim() !== '')
        }
        this.columns = applyAdditionalColumnFilters(this.columns)
      } catch (error) {
        if (error.response) {
          errorHandler(this.$toast, error.response.statusText, error)
        }
      }
    },
    moveRight () {
      this.potentialDisplayColumns = this.potentialDisplayColumns?.concat(this.columnsOptions?.filter(x =>
        this.selectedLeft?.filter(y => y === x.key).length > 0))
      this.columnsOptions = this.columnsOptions?.filter(x => this.selectedLeft?.filter(y =>
        y === x.key).length === 0)
      this.selectedLeft = []
      this.selectedRight = []
    },
    moveLeft () {
      this.columnsOptions = this.columnsOptions?.concat(this.potentialDisplayColumns?.filter(x =>
        this.selectedRight?.filter(y => y === x.key).length > 0))
      this.columnsOptions.sort((a, b) => a.label - b.label)
      this.potentialDisplayColumns = this.potentialDisplayColumns?.filter(x =>
        this.selectedRight?.filter(y => y === x.key).length === 0)
      this.selectedRight = []
      this.selectedLeft = []
    },
    moveUp () {
      if (this.selectedRight.length === 0) {
        return
      }
      const displayColumns = this.potentialDisplayColumns
      this.selectedRight.forEach(item => {
        const index = displayColumns?.findIndex(x => x.key === item)
        if (index === 0) {
          return
        }
        [displayColumns[index], displayColumns[index - 1]] =
         [displayColumns[index - 1], displayColumns[index]]
      })
      this.potentialDisplayColumns = []
      this.potentialDisplayColumns = displayColumns
      this.selectedLeft = []
    },
    moveDown () {
      if (this.selectedRight.length === 0) {
        return
      }
      const displayColumns = this.potentialDisplayColumns
      this.selectedRight.forEach(item => {
        const index = displayColumns?.findIndex(x => x.key === item)
        if (index === displayColumns.length - 1) {
          return
        }
        [displayColumns[index], displayColumns[index + 1]] =
         [displayColumns[index + 1], displayColumns[index]]
      })
      this.potentialDisplayColumns = []
      this.potentialDisplayColumns = displayColumns
      this.selectedLeft = []
    },
    onColumnsDisplay (isMobile) {
      this.isMobile = isMobile ?? false
      this.getColumns()
      this.getDisplayColumns()
      this.beginEditDisplayColumns()
      this.resetManageColumnsClass()
    },
    cancel () {
      $('#columnsDisplaySetting').removeClass('showPopupValue')
      $('#columnsDisplaySetting').modal('hide')
      this.isColumnsDisplaySetting = false
      this.selectedRight = []
      this.selectedLeft = []
    },
    async beginEditDisplayColumns () {
      if (!this.columns) {
        return
      }
      try {
        this.potentialDisplayColumns = this.displayColumns ?? []
        if (this.potentialDisplayColumns && this.potentialDisplayColumns.length > 0) {
          this.potentialDisplayColumns = this.potentialDisplayColumns.filter(x => x.label.trim() !== '')
        }
        this.columnsOptions = this.columns.filter(x => !this.displayColumns?.filter(y =>
          y.key === x.key).length > 0)
        if (this.columnsOptions.length) {
          this.columnsOptions = this.columnsOptions.filter(x => x.label.trim() !== '')
        }
        if (this.mobileColumnFirst) {
          this.columnsOptions = this.columnsOptions.filter(
            x => x.label.trim() !== this.mobileColumnFirst.label)
        }
        this.setWrapColumnValue()
        this.$nextTick(() => {
          $('#columnsDisplaySetting').addClass('showPopupValue')
          $('#columnsDisplaySetting').modal('show')
          this.isColumnsDisplaySetting = true
        })
      } catch (error) {
        if (error.response) {
          errorHandler(this.$toast, error.response.statusText, error)
        }
      }
    },
    async refreshDisplayColumns () {
      if (!this.columns) {
        return
      }
      try {
        this.potentialDisplayColumns = this.displayColumns ?? []
        if (this.potentialDisplayColumns && this.potentialDisplayColumns.length > 0) {
          this.potentialDisplayColumns = this.potentialDisplayColumns.filter(x => x.label.trim() !== '')
        }
        this.columnsOptions = this.columns.filter(x => !this.displayColumns?.filter(y =>
          y.key === x.key).length > 0)
        if (this.columnsOptions) {
          this.columnsOptions = this.columnsOptions.filter(x => x.label.trim() !== '')
        }
      } catch (error) {
        if (error.response) {
          errorHandler(this.$toast, error.response.statusText, error)
        }
      }
    },
    getDisplayColumns () {
      this.displayColumns = null
      this.loading = true
      try {
        let displayColumns = this.localUserStorage.getData(this.getTableName)
        if (displayColumns && displayColumns.length > 0) {
          displayColumns = JSON.parse(displayColumns)
          const obj = displayColumns.find(x => x.page === this.pageName &&
           x.userid === this.userId && x.gridHeadr === this.gridHeaderName && x.isMobile === this.isMobile &&
           x.orgId === this.orgId)
          if (obj) {
            obj.columns = JSON.parse(obj.columns)
            obj.columns = applyAdditionalColumnFilters(obj.columns)
            const isMobileHeader = obj.columns.filter(x => x.data != undefined)[0]
            if (isMobileHeader) {
              obj.columns = isMobileHeader?.data?.filter(x => x.label.trim() !== '')
            }
            if (this.isMobile) {
              this.columns = this.chosenLanguageHeader.desktop.filter(x => x.label.trim() !== '')
              this.mobileHeader = this.chosenLanguageHeader.mobile.filter(x => x.label.trim() !== '')
              obj.columns = obj.columns?.map(x => {
                const result = this.chosenLanguageHeader.desktop?.find(y => y.key === x.key)
                return result
              })
              if (this.mobileColumnFirst) {
                obj.columns = obj.columns.filter(
                  x => x.label.trim() !== this.mobileColumnFirst.label)
                this.columns = this.columns.filter(
                  x => x.label.trim() !== this.mobileColumnFirst.label)
              }
            } else {
              obj.columns = obj.columns?.map(x => {
                const result = this.chosenLanguageHeader.desktop?.find(y => y.key === x.key)
                return result
              })
            }
            this.displayColumns = obj.columns ?? this.columns
          } else {
            this.displayColumns = this.columns
          }
        } else {
          this.displayColumns = this.columns
        }
      } catch (error) {
        this.displayColumns = this.columns
        if (error.response) {
          errorHandler(this.$toast, error.response.statusText, error)
        }
      } finally {
        this.loading = false
      }
    },
    async storeColumnsData () {
      this.saving = true
      let postData = this.potentialDisplayColumns
      const arrayList = []
      const obj = {}
      obj.page = this.pageName
      obj.userid = this.userId
      obj.gridHeadr = this.gridHeaderName
      obj.isMobile = this.isMobile
      obj.orgId = this.orgId
      if (this.isMobile) {
        this.mobileHeader.forEach(item => {
          if (item.label == 'Info') {
            item.data = []
            item.data = this.potentialDisplayColumns
          }
        })
        postData = this.mobileHeader
      }
      obj.columns = JSON.stringify(postData)
      arrayList.push(obj)
      this.localUserStorage.saveData('wrapColumnText', this.wrapColumnText)
      try {
        let existsColumnsData = this.localUserStorage.getData(this.getTableName)
        if (existsColumnsData && existsColumnsData.length > 0) {
          existsColumnsData = JSON.parse(existsColumnsData)
          const existsCoulmnsIndex = existsColumnsData.findIndex(x => x.page === obj.page &&
            x.userid === obj.userid && x.gridHeadr === obj.gridHeadr && x.isMobile === obj.isMobile)
          if (existsCoulmnsIndex >= 0) {
            existsColumnsData.splice(existsCoulmnsIndex, 1)
          }
          existsColumnsData.push(obj)
          this.localUserStorage.saveData(this.getTableName, JSON.stringify(existsColumnsData))
        } else {
          this.localUserStorage.saveData(this.getTableName, JSON.stringify(arrayList))
        }
        successHandler(this.$toast, this.$t('message.success.save'))
        this.$emit('onRefresh')
      } catch (error) {
        if (error.response) {
          errorHandler(this.$toast, error.response.statusText, error)
        }
      }
      $('#columnsDisplaySetting').removeClass('showPopupValue')
      $('#columnsDisplaySetting').modal('hide')
      this.isColumnsDisplaySetting = false
      this.saving = false
    },
    resetGridColumns () {
      const GridSettings = localStorage.getItem('GridSettings')
      const userId = this.userId
      const pageName = this.pageName
      const orgId = this.orgId
      if (GridSettings != null) {
        const userGridSettings = JSON.parse(GridSettings)
        const userGridData = userGridSettings.filter(function (item) {
          return (item.userId === userId && item.pageName !== pageName &&
          item.orgId === orgId)
        })
        localStorage.setItem('GridSettings', JSON.stringify(userGridData))
      }
      this.$emit('onRefresh')
    },
    onTextWrappingSwitchChanged (value) {
      this.wrapColumnText = value
    },
    setWrapColumnValue () {
      const wrapColumnText = this.localUserStorage.getData('wrapColumnText')
      if (wrapColumnText === true) {
        this.wrapColumnText = true
        this.tableColumnWrapClass = 'table-wrapText'
      } else {
        this.wrapColumnText = false
        this.tableColumnWrapClass = 'table-nowraptext'
      }
    },
    additionalSettingsCallback (value) {
      this.$emit('additionalSettingsCallback', value)
    },
    setDefaultColums (displayColumns) {
      this.potentialDisplayColumns = displayColumns
      this.storeColumnsData()
    },
    async selectedRowHighlight (index, className) {
      document.onselectstart = () => { return false }
      const node = window.event.target
      if (node.nodeName === 'TD') {
        this.tableRows = document.getElementsByClassName(className)
        const currenttr = this.tableRows[index]
        if (this.lastSelectedRow === undefined) {
          this.lastSelectedRow = currenttr
        }
        const shiftKey = window.event.shiftKey
        const ctrlKey = window.event.ctrlKey
        const button = window.event.button
        if (ctrlKey) {
          this.toggleRow(currenttr, className)
        }
        if (button === 0) {
          if (!ctrlKey && !shiftKey) {
            this.clearRows(className)
            this.toggleRow(currenttr, className)
          }
          if (shiftKey) {
            await this.selectRowsBetweenIndexes(
              [this.lastSelectedRow.sectionRowIndex, currenttr.sectionRowIndex],
              className)
          }
        }
      }
    },
    toggleRow (row, className) {
      row.className = row.className == className + ' activeCell'
        ? className + ' cell' : className + ' activeCell'
      this.lastSelectedRow = row
      this.addColumnInArray(className)
    },
    selectRowsBetweenIndexes (indexes, className) {
      indexes.sort(function (a, b) {
        return a - b
      })
      for (var i = indexes[0]; i <= indexes[1]; i++) {
        this.tableRows[i].className = className + ' activeCell'
      }
      this.addColumnInArray(className)
    },
    clearRows (className) {
      for (var i = 0; i < this.tableRows.length; i++) {
        this.tableRows[i].className = className + ' cell'
      }
    },
    resetManageColumnsClass () {
      const leftTable = document.getElementsByClassName('manageColumnLeft')
      const rightTable = document.getElementsByClassName('manageColumnRight')
      for (var i = 0; i < leftTable.length; i++) {
        leftTable[i].className = 'manageColumnLeft cell'
      }
      for (var k = 0; k < rightTable.length; k++) {
        rightTable[k].className = 'manageColumnRight cell'
      }
    },
    addColumnInArray (className) {
      if (className === 'manageColumnLeft') {
        this.selectedLeft = []
        for (var i = 0; i < this.tableRows.length; i++) {
          if (this.tableRows[i].classList.contains('activeCell')) {
            this.selectedLeft.push(this.tableRows[i].getAttribute('value'))
          }
        }
      } else {
        this.selectedRight = []
        for (var k = 0; k < this.tableRows.length; k++) {
          if (this.tableRows[k].classList.contains('activeCell')) {
            this.selectedRight.push(this.tableRows[k].getAttribute('value'))
          }
        }
      }
    },
    keyExistsInObject (obj) {
      const keysOfObj = Object.keys(obj)
      return keysOfObj.length
    }
  },
  computed: {
    primaryColor () {
      var root = document.querySelector(':root')
      var styles = getComputedStyle(root)
      return styles.getPropertyValue('--primary-color')
    },
    isCheckedAll () {
      if (!this.data.length) return false
      if (this.data.some(data => data.Selected)) {
        return !this.data.some(data => !data.Selected)
      } else if (this.data.some(data => data.Checked)) {
        return !this.data.some(data => !data.Checked)
      }
      return !this.data.some(data => !data.checked)
    },
    isCheckedAllWithKey () {
      if (!this.data.length) return false
      return !this.data.some(data => !data.isCheckIn)
    },
    scrollData () {
      return this.data.slice(0,
        this.pageSize === 0
          ? this.defaultPageSize
          : this.pageSize)
    },
    mergedHeader () {
      // Copy the English toolbar items so we don't modify them directly
      const mergedHeader = deepCopy(this.englishHeader)

      if (mergedHeader.desktop) {
        // Ensure that both headers have the same number of entries
        const desiredLength = this.chosenLanguageHeader.desktop.length
        mergedHeader.desktop = mergedHeader.desktop.slice(0, desiredLength)
        if (mergedHeader.desktop.length !== desiredLength) {
          throw new Error('English desktop header has different number of entries than current desktop header')
        }
        try {
          const emptyColumnsDesktop = this.chosenLanguageHeader.desktop.filter(x => x.label.trim() === '')

          // Get user chosen columns to display
          let displayColumns = this.localUserStorage.getData(this.getTableName)
          if (displayColumns && displayColumns.length > 0) {
            displayColumns = JSON.parse(displayColumns)
            const objDesktop = displayColumns.find(x => x.page === this.pageName && x.userid === this.userId &&
            x.gridHeadr === this.gridHeaderName && x.isMobile === false && x.orgId === this.orgId)
            if (objDesktop) {
              objDesktop.columns = JSON.parse(objDesktop.columns)
              objDesktop.columns = applyAdditionalColumnFilters(objDesktop.columns)

              // Find each of the user picked columns in our master list of columns for this grid
              objDesktop.columns = objDesktop.columns?.map(x => {
                const result = this.chosenLanguageHeader.desktop?.find(y => y.key === x.key)
                return result
              })

              // ALIGN-8153: If any of the user columns didn't find a match, clear the user columns
              // from memory and load the default columns.  This would happen if we ever change a
              // key in the master defined columns for the grid, the lines directly above this
              // would fail to find a match.
              if (objDesktop.columns.some(x => x === undefined || x === null)) {
                this.localUserStorage.removeData(this.getTableName)
                objDesktop.columns = []
              }

              if (objDesktop.columns && objDesktop.columns.length > 0) {
                emptyColumnsDesktop?.forEach((data) => {
                  const indexOf = this.chosenLanguageHeader.desktop?.map(y => y.key).indexOf(data.key)
                  const colmnlenth = objDesktop.columns.length
                  if (colmnlenth <= indexOf) {
                    objDesktop.columns.splice(colmnlenth + 1, 0, data)
                  } else {
                    objDesktop.columns.splice(indexOf, 0, data)
                  }
                })
              }
              mergedHeader.desktop = (objDesktop.columns && objDesktop.columns.length > 0) ? objDesktop.columns
                : mergeObjects(mergedHeader.desktop, this.chosenLanguageHeader.desktop)
            }
          } else {
            const hasDefaultDisplay = mergedHeader.desktop.some(e => e.dafeultDisplay)
            if (hasDefaultDisplay) {
              const displayDefaultColumns = mergedHeader.desktop.filter(obj => Object.keys(obj).includes('dafeultDisplay'))
              this.setDefaultColums(displayDefaultColumns)
            }
            mergeObjects(mergedHeader.desktop, this.chosenLanguageHeader.desktop)
          }
        } catch (e) {
          mergeObjects(mergedHeader.desktop, this.chosenLanguageHeader.desktop)
        }
        mergedHeader.desktop = applyAdditionalColumnFilters(mergedHeader.desktop)
      }

      if (mergedHeader.mobile) {
        if (mergedHeader.mobile.length !== this.chosenLanguageHeader.mobile.length) {
          throw new Error('English mobile header has different number of entries than current mobile header')
        }
        try {
          const emptyColumnsMobile = mergedHeader.mobile.filter(x => x.label.trim() === '')
          let displayMobileColumns = this.localUserStorage.getData(this.getTableName)
          if (displayMobileColumns && displayMobileColumns.length > 0) {
            displayMobileColumns = JSON.parse(displayMobileColumns)
            const objMobile = displayMobileColumns.find(x => x.page === this.pageName && x.userid === this.userId &&
             x.gridHeadr === this.gridHeaderName && x.isMobile === true && x.orgId === this.orgId)
            if (objMobile) {
              objMobile.columns = JSON.parse(objMobile.columns)
              objMobile.columns = applyAdditionalColumnFilters(objMobile.columns)
              objMobile.columns = objMobile.columns?.map(x => {
                const result = this.chosenLanguageHeader.mobile?.find(y => y.key === x.key)
                return result
              })
              mergedHeader.mobile = emptyColumnsMobile.concat(objMobile.columns)
            }
          } else {
            mergeObjects(mergedHeader.mobile, this.chosenLanguageHeader.mobile)
          }
        } catch (e) {
          mergeObjects(mergedHeader.mobile, this.chosenLanguageHeader.mobile)
        }
        mergedHeader.mobile = applyAdditionalColumnFilters(mergedHeader.mobile)
      }

      // Return the Object properties as an array,
      // so we can iterate through them to create the toolbar items
      return mergedHeader
    },
    headerCheckboxVisible () {
      const headerCheckboxArr = this.mergedHeader.desktop
        .filter(x => x.type == 'check' && x.headerCheckboxVisible != false)
      return headerCheckboxArr.length > 0
    },
    getTableName () {
      return this.pageName.concat('_', this.gridHeaderName)
    },
    gridHeaderName () {
      const tableName = this.pathToHeader.split('.')
      const tableNameArray = tableName.filter(function (el) {
        return el != ''
      })
      return tableNameArray.length == 2 ? tableNameArray[0] : this.pathToHeader
    },
    userId () {
      return localStorage.getItem('UserId')
    },
    orgId () {
      return localStorage.getItem('OrgId')
    },
    pageName () {
      const pageUrl = this.$route.path.split('/')
      const pageUrlArr = pageUrl.filter(function (el) {
        return el != ''
      })
      return pageUrlArr[pageUrlArr.length - 1]
    },
    englishHeader () {
      return this.$t(this.pathToHeader, 'en', {})
    },

    chosenLanguageHeader () {
      return this.$t(this.pathToHeader)
    },
    cssVariables () {
      return {
        '--min-height': this.rowComponentData.props.minHeight
          ? this.rowComponentData.props.minHeight
          : '300px',
        '--subtracted-height': this.rowComponentData.props.subtractedHeight
          ? this.rowComponentData.props.subtractedHeight
          : '310px'
      }
    },
    dataLength () {
      return this.data.length
    }
  },
  watch: {
    mergedHeader: {
      handler: function () {
        this.getSortable()
        this.getColumns()
        this.getDisplayColumns()
        this.refreshDisplayColumns()
      },
      immediate: true
    },
    dataLength () {
      if (this.$refs.gridInfiniteLoader) {
        this.$refs.gridInfiniteLoader.stateChanger.reset()
      }
    },
    tableData: {
      handler (updatedData) {
        this.data = updatedData

        // Bugs-450: Up the chain of components, filtering is often done at the parent level
        // by calling this.emit('onDataFilter').  If so, filtering was already done by the time we got here
        // and we do not need to filter again.  Filtering can be expensive on large data
        // sets in places like item browser.
        const filteringIsDoneExternally = !!this.$listeners.onDataFilter
        if (filteringIsDoneExternally) {
          return
        }

        const keyExists = this.keyExistsInObject(this.tableFilter)
        if (this.hasFilters && keyExists) {
          this.applyFilters()
        } else if (this.hasFilters) {
          this.tableFilter = this.tableFilters
          this.applyFilters()
        } else {
          this.tableFilter = {}
        }
      },
      deep: true
    }
  }
}
</script>
<style scoped>
:root {
  --secondary-color: #D09433;
  --badge-secondary-color: #FBE7B5;
}
.tw-grid-container .footable tr th {
  position: sticky;
  top: 0;
  z-index: 100;
}
.tw-grid-container .footable tr td {
  min-width: 45px;
}
.tw-grid-container .footable tr td i {
  color: red;
}
.tw-grid-container .footable tr.selected a {
  color: white;
}
.tw-grid-container .footable-holder {
  min-height: var(--min-height);
}
table {
  table-layout: fixed;
}
.footable-holder {
  height: calc(100vh - var(--subtracted-height));
  max-width: 100%;
  overflow-x: auto;
}
.tw-grid-total-rows {
  text-align: center;
  padding-top: 5px;
}
.favorite-manage {
  min-height: 350px;
  overflow: auto;
}
.settingbuttons {
  border-radius: 3px;
  font-size: inherit;
  background: var(--primary-color);
}
.cell {
    cursor: default;
}
.activeCell {
    background-color: #1967D2;
    color: white;
    cursor: default;
}
.showPopupValue {
  display: contents !important;
}
#columnsDisplaySetting > .modal-dialog {
  margin-top: -50px !important;
  position: absolute !important;
}
</style>
