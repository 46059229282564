const listVisibility = {
  title: 'List Visibility',
  stockLocationsLabel: 'Stock Location to Grant Visibility',
  listsLabel: 'List Selection',
  allStockLocations: '... Set Visibility Globally (All Stock Locations)',
  allLists: '... Set All Lists Globally',
  setAllVisible: 'Make All Lists Visible at the Selected Location(s)',
  cancelChangesBtn: 'Cancel',
  getListItemsTitle: 'Getting List Items and Visibility',
  getCountsTitle: 'Counting List Items to Update',
  confirmChangesTitle: 'List Visibility Changes',
  confirmChangesMsgPrefix: 'The changes will update visibility for ',
  confirmChangesMsgSuffix: ' list items. Are you sure you want to save?',
  confirmCancelTitle: 'Cancel Changes',
  confirmCancelMessage: 'The changes made will not be saved. Are you sure you want to cancel?',
  setVisibilityTitle: 'Updating List Visibility',

  categoriesAll: 'Categories (All)',
  categoriesMaterials: 'Categories (Material)',
  categoriesTemplates: 'Categories (Template)',
  categoriesTools: 'Categories (Tool)',
  certifications: 'Certifications',
  classes: 'Classes',
  contractors: 'Contractors',
  customers: 'Customers',
  departments: 'Departments',
  employeesAll: 'Employees (All)',
  employeesActive: 'Employees (Active)',
  employeesInactive: 'Employees (Inactive)',
  employeeTypes: 'Employee Types',
  locationsAll: 'Locations (All)',
  locationsActive: 'Locations (Active)',
  locationsInactive: 'Locations (Inactive)',
  manufacturers: 'Manufacturers',
  shippingMethods: 'Shipping Methods',
  statusCodes: 'Status Codes',
  uom: 'Units of Measure',
  vendors: 'Vendors',
  costCentersAll: 'Cost Centers (All)',
  costCentersActive: 'Cost Centers (Active)',
  costCentersInactive: 'Cost Centers (Inactive)',
  costCodesAll: 'Cost Codes (All)',
  costCodesActive: 'Cost Codes (Active)',
  costCodesInactive: 'Cost Codes (Inactive)',
  serviceClasses: 'Service Classes',
  modelAssignedTocategoryDeleteMsg: 'You cannot delete a Category that contains assets. Please move these assets to another Category or delete them before trying to delete this Category'
}

export default listVisibility
